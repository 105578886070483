// src/App.js
import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import HomePage from './HomePage';
import ContactPage from './ContactPage';

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (<HomePage/>),
    },
    {
      path: "contact",
      element: (<ContactPage/>),
    },
  ]);

  return (
    <RouterProvider router={router} />
  );
};

export default App;