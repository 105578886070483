import React, { useState, useEffect, useRef } from 'react';
import Feature from '../../components/feature/Feature'
import './whatswmmReact.css'
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

const WhatswmmReact = () => {
  // Number of elements in the array
  const numElements = 3;

  // Array to hold refs and inView states
  const inViewArray = [];

  // Loop to create refs and inView states
  for (let i = 0; i < numElements; i++) {
    const [ref, inView] = useInView({
      triggerOnce: false,
      rootMargin: "0px 0px 200px 0px",
    });

    // Push refs and inView states to the array
    inViewArray.push({ ref, inView });
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1, transition: {duration: 1} }}
      exit={{ opacity: 0, transition: {duration: 0} }}
    >
      <div className="swmmReact__whatswmmReact section__margin" id="wswmmReact">
        <div className="swmmReact__whatswmmReact-feature">
          <Feature title="swmmReact fundamentals" text="swmmReact is a web interface for EPA-SWMM modeling systems. Create fast, responsive, live models for users to interact with environmental data, independent of hardware, operating systems, or browsers. swmmReact allows you to display environmental conditions on desktops, cellphones, and tablets of nearly any kind." />
        </div>
        <div className="swmmReact__whatswmmReact-heading">
          <h1 className="gradient__text">Create highly interactive systems to monitor your assets using live data</h1>
        </div>
        <div className="swmmReact__whatswmmReact-container">
          <motion.div
            ref={inViewArray[2].ref}
            initial={{ y: inViewArray[2].inView ? 300 : 0 }}
            animate={{ y: inViewArray[2].inView ? 0 : 300 }}
            transition={{duration: 0.5}}
          >
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1, transition: {duration: 1} }}
            exit={{ opacity: 0, transition: {duration: 0} }}
          >
            <Feature title="Platform independent" text="Run SWMM on nearly any browser-enabled computing device." />
          </motion.div>
          </motion.div>
          <motion.div
            ref={inViewArray[1].ref}
            initial={{ y: inViewArray[1].inView ? 300 : 0 }}
            animate={{ y: inViewArray[1].inView ? 0 : 300 }}
            transition={{duration: 0.5}}
          >
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1, transition: {duration: 1} }}
            exit={{ opacity: 0, transition: {duration: 0} }}
          >
            <Feature title="Real-time data" text="Connect your models to real-time rainfall monitors, velocity sensors, and early-warning detection systems in order to keep your model live and informative." />
          </motion.div>
          </motion.div>
          <motion.div
            ref={inViewArray[0].ref}
            initial={{ y: inViewArray[0].inView ? 300 : 0 }}
            animate={{ y: inViewArray[0].inView ? 0 : 300 }}
            transition={{duration: 0.5}}
          >
            <Feature title="Responsive controls" text="Use modern web controls to make changes easily and react instantly to constantly updated information." />
          </motion.div>
        </div>
      </div>
    </motion.div>
  )
}

export default WhatswmmReact;
