import React from 'react'
import './elements.css'
import { motion } from "framer-motion"

const Elements = () => {
  return (
    <div className='swmmReact__elements section__padding'>
      <motion.div
        initial={{ scale: 0 }}
        whileInView={{ scale: 1, transition: {type: 'spring', bounce: 0.5, duration: 0.5} }}
        exit={{ scale: 0, transition: {duration: 0} }}
      >
        Controls
      </motion.div>
      <motion.div
        initial={{ scale: 0 }}
        whileInView={{ scale: 1, transition: {type: 'spring', bounce: 0.5, duration: 0.5} }}
        exit={{ scale: 0, transition: {duration: 0} }}
      >
        Displays
      </motion.div>
      <motion.div
        initial={{ scale: 0 }}
        whileInView={{ scale: 1, transition: {type: 'spring', bounce: 0.5, duration: 0.5} }}
        exit={{ scale: 0, transition: {duration: 0} }}
      >
        Data Management
      </motion.div>
      <motion.div
        initial={{ scale: 0 }}
        whileInView={{ scale: 1, transition: {type: 'spring', bounce: 0.5, duration: 0.5} }}
        exit={{ scale: 0, transition: {duration: 0} }}
      >
        Live Files
      </motion.div>
      <motion.div
        initial={{ scale: 0 }}
        whileInView={{ scale: 1, transition: {type: 'spring', bounce: 0.5, duration: 0.5} }}
        exit={{ scale: 0, transition: {duration: 0} }}
      >
        Library Interactivity
      </motion.div>
    </div>
  )
}

export default Elements