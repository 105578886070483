import React from 'react'
import { DropIcon } from '../dropIcon/DropIcon.jsx'
import './listElementAnimated.css'

const ListElementAnimated = ({text, link}) => {
  const [isActive, setIsActive] = React.useState(false)

  const handleMouseOver = () => {
    setIsActive(true)
  }

  const handleMouseOut = () => {
    setIsActive(false)
  }
  return (
    <>
      <div
        onMouseOver={handleMouseOver} 
        onMouseOut={handleMouseOut} >
        <a href={link}>
          <div className="listIcon" >
            <div className='dropIcon'>
              <DropIcon className="dropicon" parentActive={isActive}/>
            </div>
            <p>{text}</p>
          </div>
        </a>
      </div>
    </>
  )
}

export {ListElementAnimated}