import React from 'react'
import './possibility.css'

import { DropIcon } from '../../components/dropIcon/DropIcon'

const Possibility = () => (
  <div className="swmmReact__possibility section__padding" id="possibility">
    <div className="swmmReact__possibility-image">
      <DropIcon className='listIcon' parentActive={true} alt='people'/>
    </div>
    <div className="swmmReact__possibility-content">
      <h4>swmmReact is open-source, free software</h4>
      <h1 className="gradient__text">The next step for swmm-js</h1>
      <p>swmm-js was developed as open-source, free software, and swmmReact follows in this tradition. The goal of both swmmReact and swmm-js is to simplify the process of understanding and interacting with environmental models, and to improve public understanding of important environmental issues.</p>
      <h4><a href='https://github.com/swmm-js'>See the code and examples on GitHub</a></h4>
    </div>
  </div>
);

export default Possibility;