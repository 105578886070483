import React, { useRef, useEffect} from 'react';
import Feature from '../../components/feature/Feature'
import './features.css';
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"


const Features = () => {
  const [mainRef, mainInView] = useInView({
    triggerOnce: false,
    rootMargin: "0px 0px 200px 0px",
  });

  // Number of elements in the array
const numElements = 4;

// Array to hold refs and inView states
const inViewArray = [];

// Loop to create refs and inView states
for (let i = 0; i < numElements; i++) {
  const [ref, inView] = useInView({
    triggerOnce: false,
    rootMargin: "0px 0px 200px 0px",
  });

  // Push refs and inView states to the array
  inViewArray.push({ ref, inView });
}

  const featuresData = [
    {
      title: 'Communicate',
      text: 'Use simple controls to quickly display important information.',
    },
    {
      title: 'Collaborate',
      text: 'Run your models using live files stored in the cloud or locally.',
    },
    {
      title: 'Demonstrate',
      text: 'Don\'t just tell someone how a modification will change a model, SHOW them with interactive displays.',
    },
    {
      title: 'Replicate',
      text: 'Utilize many of the pre-built options to quickly create charts, tables, models, and maps.',
    },
  ];

  return (
    <motion.div
      ref={mainRef}
      initial={{ x: mainInView ? 300 : 0 }}
      animate={{ x: mainInView ? 0 : 300 }}
      transition={{duration: 0.5}}
    >
      <div className="swmmReact__features section__padding" id="features">
        <div className="swmmReact__features-heading">
          <h1 ref={null} className="gradient__text">Use swmmReact components and systems to communicate with team members, clients, and the world. </h1>
          <p>Display models and results seamlessly</p>
        </div>
        <div className="swmmReact__features-container">
          {featuresData.map((item, i) => (
            <motion.div
            key={item+i}
              ref={inViewArray[i].ref}
              initial={{ y: inViewArray[i].inView ? 300 : 0 }}
              animate={{ y: inViewArray[i].inView ? 0 : 300 }}
              transition={{duration: 0.5}}
            >
              <Feature objRef={null} title={item.title} text={item.text} />
            </motion.div >
          ))}
        </div>
      </div>
    </motion.div>
  )
}

export default Features;


          //{/*<Feature objRef={refArray[index]} title={item.title} text={item.text} key={item.title + index} />*/}