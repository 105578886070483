// ContactPage.js
import React, { useRef, useEffect } from 'react'
import { Contact } from './containers'
import {
  Link,
} from "react-router-dom"
import './containers/contact/contact.css'

const ContactPage = () => {
  const parallaxRef = useRef()
  const textRef = useRef()
  const backgroundRef = useRef()
  const foregroundRef = useRef()
  const poolRef = useRef()
  const waterfallRef = useRef()

  const handleScroll = () => {
      const position = window.pageYOffset
      
      if(position < 1200){
        backgroundRef.current.style.marginTop = position * 1.1 + 'px'
        backgroundRef.current.style.transform = `scale(${1+position*0.0005})`
        backgroundRef.current.style.filter = `blur(${position*0.005}px)`
        poolRef.current.style.transform = `scaleY(${position*0.001>1?0:1-position*0.001})`
        poolRef.current.style.transform = `scaleX(${position*0.001>1?0:1+position*0.001})`
        poolRef.current.style.marginTop = position * 1.1 + 'px'
        waterfallRef.current.style.transform = `scale(${1+position*0.0008})`
        waterfallRef.current.style.marginTop = Math.min(position, ((parallaxRef.current.offsetHeight-300)*.85)/2) * .9 + 'px'
        waterfallRef.current.style.filter = `blur(${position*0.005}px)`
        foregroundRef.current.style.transform = `scale(${1+position*0.002})`
        foregroundRef.current.style.marginTop =  -(Math.min(position, 500) * 0.3) + 'px'
        textRef.current.style.fontSize = `${3+position*0.01}em`
        textRef.current.style.marginTop = Math.min(position, (parallaxRef.current.offsetHeight*.85)/2.5) * 2.5 + 'px'
      }
  }
  
  useEffect(() => {
      window.addEventListener('scroll', handleScroll, { passive: true })
  
      return () => { window.removeEventListener('scroll', handleScroll) }
  }, [])

  return (
    <>
      <header>
        <h2 className="logo"><img src='./logo128.png' style={{height: '1.5em'}}></img></h2>
        <nav className="navigation">
          <Link to="../">Home</Link>
          <Link className='active' to="#">Contact</Link>
        </nav>
      </header>
      <section ref={parallaxRef} className="parallax" style={{marginTop: '-150px'}}>
        {<img ref={backgroundRef} src="./background.png" alt="An image" id="background"></img>}
        <img ref={poolRef} src="./pool.png" alt="An image" id="pool"></img>
        <img ref={waterfallRef} src="./waterfall.png" alt="An image" id="waterfall"></img>
        <img ref={foregroundRef} src="./foreground.png" alt="An image" id="foreground"></img>
        <h2 ref={textRef} style={{marginBottom: '0'}} id="text">swmmReact</h2>
      </section>
      <div style={{height: '100vh' }}>
        <div style={{position: 'fixed', top: '80vh'}}>
          <Contact />
        </div>
      </div>
    </>
  );
};

export default ContactPage;