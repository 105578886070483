import React from 'react';
import './cta.css';

const CTA = () => (
  <div className="swmmReact__cta">
    <div className="swmmReact__cta-content">
      <p>Take a second to see what swmmReact can do</p>
      <h3>Check out some of the display tools already available.</h3>
    </div>
    <div className="swmmReact__cta-btn">
      <a href='https://github.com/swmm-js'>
        <button type="button">Get Started</button>
      </a>
    </div>
  </div>
);

export default CTA;