import React from 'react'
import './contact.css'

const Contact = () => (
  <>
    <div className="swmmReact__footer-links">
      <div className="swmmReact__footer-links_logo">
        <p>swmmReact & blcnblu</p>
      </div>
      <div className="swmmReact__footer-links_div">
        <h4>Links</h4>
        <a href="mailto: issac@swmmReact.org">
          <p>Email me</p>
        </a>
      </div>
      <div className="swmmReact__footer-links_div">
        <h4>Development</h4>
        <a href='https://github.com/swmm-js'>
          <p>Github </p>
        </a>
        <a href='https://www.npmjs.com/package/@fileops/swmm-node'>
          <p>npm: swmmNode</p>
        </a>
        <a href='https://www.npmjs.com/package/@fileops/swmm-wasm'>
          <p>npm: swmmWasm</p>
        </a>
      </div>
      <div className="swmmReact__footer-links_div">
        <h4>Contact:</h4>
        <p>PO Box 586</p>
        <p>Beaverton, OR 97075</p>
        <p><a href="mailto: issac@swmmReact.org">issac@swmmreact.org</a></p>
      </div>
    </div>

    <div className="swmmReact__footer-copyright">
      <p>@2023 swmmReact/blcnblu. All rights reserved.</p>
    </div>
  </>
)

export default Contact