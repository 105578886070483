// HomePage.js
import React, { useState, useRef, useEffect } from "react"
import { Footer, Blog, Possibility, Features, WhatswmmReact, Header } from './containers'
import { CTA, Elements, Navbar, DropList } from './components'
import {
  Link,
} from "react-router-dom"
import './HomePageIndex.css'
import './HomePage.css'
import {motion, useScroll, useMotionValueEvent} from 'framer-motion'
import ParallaxHero from "./ParallaxHero"

export default function HomePage() {
  return (
    <>
      <header>
        <h2 className="logo"><img src='./logo128.png' style={{height: '1.5em'}}></img></h2>
        <nav className="navigation">
          <a href="#" className='active'>Home</a>
          <Link to="contact">Contact</Link>
        </nav>
      </header>
      <ParallaxHero />

      <section className="sec">
        <DropList />
        <Elements />
        <WhatswmmReact />
        <Features />
        <Possibility />
        <CTA />
        <Footer />
      </section>
    </>
  )
}
