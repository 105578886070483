import React from 'react';
import './footer.css';
import { Contact } from '../../containers'

const Footer = () => (
  <div className="swmmReact__footer section__padding">
    <div className="swmmReact__footer-heading">
      <h1 className="gradient__text">visualize environmental data with live, interactive tools </h1>
    </div>

    <div className="swmmReact__footer-btn">
      <a href='https://github.com/swmm-js'>
        <p>show me how</p>
      </a>
    </div>

    <Contact />
  </div>
);

export default Footer;