import React from 'react'
import {ReactComponent as DropIconSVG} from '../../assets/raindrop/raindrop.svg'

import '../../assets/raindrop/raindrop.css'

const DropIcon = ({parentActive}) => {
  return (
    <>
      <DropIconSVG 
        className={parentActive?'active':''}/>
    </>
  )
}

export { DropIcon };