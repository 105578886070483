import React, { useState, useRef, useEffect } from "react"
import { ListElementAnimated } from '../listElementAnimated/ListElementAnimated.jsx'
import './droplist.css'
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

const DropList = () => {
  const linkSet = [
    {
      setName: 'Live Models',
      linkData: [
        {link: "../swmmReact/multi-perspective/", text: 'Multi-perspective presentation'},
        {link: "../swmmNode/spatiallyDistributedRainfall/", text: 'GeoSpatial Rainfall Modeling'},
        {link: "../swmmNode/subareaRouting/", text: 'Subarea UI Controls'},
        {link: "../swmmNode/OutfallModeling/", text: 'Outfall Modeling'},
        {link: "../swmmNode/ConduitLengths/", text: 'Conduit Lengths'},
        {link: "../swmmNode/JunctionPondedArea/", text: 'Junction Ponded Area'},
        {link: "../swmmNode/JunctionMaxDepth/", text: 'Junction Maximum Depths'},
        {link: "../swmmNode/JunctionInvertElevation/", text: 'Junction Inverts'},
        {link: "../swmmNode/subcatchmentCalibration/", text: 'Subcatchment Calibration'},
        {link: "../swmmReact/LiveModelRainFlow/", text: 'Live Model RainFlow Charts'},
        {link: "../swmmReact/swmmNode_website_stormfinder/", text: 'swmmNode Storm Detection'},
        {link: "https://swmmreact.org/swmmNode/periodVolumes/", text: 'swmmNode Event Volumes'},
        {link: "../swmmReact/swmmNode_basic_example/", text: 'swmmNode .OUT File Text Translation'},
        {link: "../swmmReact/LiveModelMapping/", text: 'Live Model Mapping'},
        {link: "../swmmReact/LiveModelEditing/", text: 'Live Model Editing'}
      ],
    },
    {
      setName: 'Link Charting',
      linkData: [
        {link: "../swmmReact/app_C_CHART_Link_Capacity/", text: 'Link Capacity'},
        {link: "../swmmReact/app_C_CHART_Link_FroudeNumber/", text: 'Link Froude Number'},
        {link: "../swmmReact/app_C_CHART_Link_FlowVelocity/", text: 'Link Flow Velocity'},
        {link: "../swmmReact/app_C_CHART_Link_FlowRate/", text: 'Link Flow Rate'}
      ],
    },
    {
      setName: 'Node Charting',
      linkData: [
        {link: "../swmmReact/app_C_CHART_Node_Overflow/", text: 'Node Overflow'},
        {link: "../swmmReact/app_C_CHART_Node_TotalInflow/", text: 'Node TotalInflow'},
        {link: "../swmmReact/app_C_CHART_Node_LateralInflow/", text: 'Node Lateral Inflow'},
        {link: "../swmmReact/app_C_CHART_Node_VolStoredPonded/", text: 'Node Volume Stored & Ponded'},
        {link: "../swmmReact/app_C_CHART_Node_HydraulicHead/", text: 'Node Hydraulic Head'},
        {link: "../swmmReact/app_C_CHART_Node_DepthAboveInvert/", text: 'Node Depth Above Invert'}
      ],
    },
    {
      setName: 'Subcatchment Charting',
      linkData: [
        {link: "../swmmReact/app_C_CHART_Subcatch_PollutantWashoff/", text: 'Subcatchment Pollutant Washoff'},
        {link: "../swmmReact/app_C_CHART_Subcatch_SoilMoisture/", text: 'Subcatchment Soil Moisture'},
        {link: "../swmmReact/app_C_CHART_Subcatch_GWElevation/", text: 'Subcatchment GW Elevation'},
        {link: "../swmmReact/app_C_CHART_Subcatch_GWOutflow/", text: 'Subcatchment GW Outflow'},
        {link: "../swmmReact/app_C_CHART_Subcatch_RunoffRate/", text: 'Subcatchment Runoff Rate'},
        {link: "../swmmReact/app_C_CHART_Subcatch_InfiltrationLoss/", text: 'Subcatchment Infiltration Loss'},
        {link: "../swmmReact/app_C_CHART_Subcatch_EvaporationLoss/", text: 'Subcatchment Evaporation Loss'},
        {link: "../swmmReact/app_C_CHART_Subcatch_Snowdepth/", text: 'Subcatchment Snow Depth'},
        {link: "../swmmReact/app_C_CHART_Subcatch_Rainfall/", text: 'Subcatchment Rainfall'}
      ],
    },
    {
      setName: 'System Charting',
      linkData: [
        {link: "../swmmReact/app_C_CHART_System_PotentialET/", text: 'System Potential ET'},
        {link: "../swmmReact/app_C_CHART_System_EvaporationRate/", text: 'System Evaporation Rate'},
        {link: "../swmmReact/app_C_CHART_System_StorageVolume/", text: 'System Storage Volume'},
        {link: "../swmmReact/app_C_CHART_System_OutfallOutflow/", text: 'System Outfall Out flow'},
        {link: "../swmmReact/app_C_CHART_System_FloodingOutflow/", text: 'System Flooding Out flow'},
        {link: "../swmmReact/app_C_CHART_System_TotalLateralInflow/", text: 'System Total Lateral Inflow'},
        {link: "../swmmReact/app_C_CHART_System_ExternalInflow/", text: 'System External Inflow'},
        {link: "../swmmReact/app_C_CHART_System_RDIIInflow/", text: 'System RDII Inflow'},
        {link: "../swmmReact/app_C_CHART_System_GroundwaterInflow/", text: 'System Groundwater Inflow'},
        {link: "../swmmReact/app_C_CHART_System_DryWeatherInflow/", text: 'System Dry Weather Inflow'},
        {link: "../swmmReact/app_C_CHART_System_Runoff/", text: 'System Runoff'},
        {link: "../swmmReact/app_C_CHART_System_Infiltration/", text: 'System Infiltration'},
        {link: "../swmmReact/app_C_CHART_System_SnowDepth/", text: 'System Snow Depth'},
        {link: "../swmmReact/app_C_CHART_System_Rainfall/", text: 'System Rainfall'},
        {link: "../swmmReact/app_C_CHART_System_AirTemperature/", text: 'System Air Temperature'}
      ],
    },
    {
      setName: 'Controls: Options',
      linkData: [
        {link: "../swmmReact/app_C_OPTIONSSurchargeMethodDropDown/", text: 'Surcharge Method'},
        {link: "../swmmReact/app_C_OPTIONSIgnoreRDIIDropDown/", text: 'Ignore RDII'},
        {link: "../swmmReact/app_C_OPTIONSIgnoreQualityDropDown/", text: 'Ignore Quality'},
        {link: "../swmmReact/app_C_OPTIONSIgnoreRoutingDropDown/", text: 'Ignore Routing'},
        {link: "../swmmReact/app_C_OPTIONSIgnoreGroundwaterDropDown/", text: 'Ignore Groundwater'},
        {link: "../swmmReact/app_C_OPTIONSIgnoreSnowmeltDropDown/", text: 'Ignore Snowmelt'},
        {link: "../swmmReact/app_C_OPTIONSIgnoreRainfallDropDown/", text: 'Ignore Rainfall'},
        {link: "../swmmReact/app_C_OPTIONSThreadsNUD/", text: 'Threads'},
        {link: "../swmmReact/app_C_OPTIONSMinimumStepNUD/", text: 'Minimum Step'},
        {link: "../swmmReact/app_C_OPTIONSLatFlowTolNUD/", text: 'Lat Flow Tolerance'},
        {link: "../swmmReact/app_C_OPTIONSSysFlowTolNUD/", text: 'System Flow Tolerance'},
        {link: "../swmmReact/app_C_OPTIONSHeadToleranceNUD/", text: 'Head Tolerance'},
        {link: "../swmmReact/app_C_OPTIONSMaxTrialsNUD/", text: 'Max Trials'},
        {link: "../swmmReact/app_C_OPTIONSMinSurfareaNUD/", text: 'Minimum Surface area'},
        {link: "../swmmReact/app_C_OPTIONSLengtheningStepNUD/", text: 'Lengthening Step'},
        {link: "../swmmReact/app_C_OPTIONSVariableStepNUD/", text: 'Variable Step'},
        {link: "../swmmReact/app_C_OPTIONSForceMainEquationDropDown/", text: 'Force Main Equation'},
        {link: "../swmmReact/app_C_OPTIONSNormalFlowLimitedDropDown/", text: 'Normal Flow Limited'},
        {link: "../swmmReact/app_C_OPTIONSInertialDampingDropDown/", text: 'Inertial Damping'},
        {link: "../swmmReact/app_C_OPTIONSRoutingStepInputTime/", text: 'Routing Step'},
        {link: "../swmmReact/app_C_OPTIONSRuleStepInputTime/", text: 'Rule Step'},
        {link: "../swmmReact/app_C_OPTIONSDryStepInputTime/", text: 'Dry Step'},
        {link: "../swmmReact/app_C_OPTIONSWetStepInputTime/", text: 'Wet Step'},
        {link: "../swmmReact/app_C_OPTIONSReportStepInputTime/", text: 'Report Step'},
        {link: "../swmmReact/app_C_OPTIONSDryDaysNUD/", text: 'Dry Days'},
        {link: "../swmmReact/app_C_OPTIONSSweepEndDTP/", text: 'Sweep End'},
        {link: "../swmmReact/app_C_OPTIONSSweepStartDTP/", text: 'Sweep Start'},
        {link: "../swmmReact/app_C_OPTIONSEndTimeInputTime/", text: 'End Time'},
        {link: "../swmmReact/app_C_OPTIONSEndDateDTP/", text: 'End Date'},
        {link: "../swmmReact/app_C_OPTIONSReportStartTimeInputTime/", text: 'Report Start Time'},
        {link: "../swmmReact/app_C_OPTIONSReportStartDateDTP/", text: 'Report Start Date'},
        {link: "../swmmReact/app_C_OPTIONSStartTimeInputTime/", text: 'Start Time'},
        {link: "../swmmReact/app_C_OPTIONSStartDateDTP/", text: 'Start Date'},
        {link: "../swmmReact/app_C_OPTIONSSkipSteadyStateDropDown/", text: 'Skip Steady State'},
        {link: "../swmmReact/app_C_OPTIONSMinSlopeNUD/", text: 'Minimum Slope'},
        {link: "../swmmReact/app_C_OPTIONSLinkOffsetsDropDown/", text: 'Link Offsets'},
        {link: "../swmmReact/app_C_OPTIONSInfilModelDropDown/", text: 'Infiltration Model'},
        {link: "../swmmReact/app_C_OPTIONSAllowPondingDropDown/", text: 'Allow Ponding'}
      ],
    },
    {
      setName: 'Controls: Subcatchments',
      linkData: [
        {link: "../swmmReact/App_C_SUBCATCHMENTSForm/", text: 'Subcatchments (Form)'},
        {link: "../swmmReact/App_C_SUBCATCHMENTSAreaNUD", text: 'Area'},
        {link: "../swmmReact/App_C_SUBCATCHMENTSCurbLenNUD", text: 'Curb Length'},
        {link: "../swmmReact/App_C_SUBCATCHMENTSPctImpervNUD", text: '% Impervious'},
        {link: "../swmmReact/App_C_SUBCATCHMENTSPctSlopeNUD", text: '% Slope'},
        {link: "../swmmReact/App_C_SUBCATCHMENTSWidthNUD", text: 'Width'}
      ]
    },
    {
      setName: 'Controls: Subareas',
      linkData: [
        {link: "../swmmReact/App_C_SUBAREASNImpervNUD", text: 'N, Impervious'},
        {link: "../swmmReact/App_C_SUBAREASNPervNUD", text: 'N, Pervious'},
        {link: "../swmmReact/App_C_SUBAREASPctRoutedNUD", text: '% Routed'},
        {link: "../swmmReact/App_C_SUBAREASPctZeroNUD", text: '% Zero'},
        {link: "../swmmReact/App_C_SUBAREASSImpervNUD", text: 'Impervious Storage'},
        {link: "../swmmReact/App_C_SUBAREASSPervNUD", text: 'Pervious Storage'}
      ]
    },
    {
      setName: 'Controls: Junctions',
      linkData: [
        {link: "../swmmReact/App_C_JUNCTIONSForm", text: 'Form'},
        {link: "../swmmReact/App_C_JUNCTIONSInvertNUD", text: 'Invert'},
        {link: "../swmmReact/App_C_JUNCTIONSDmaxNUD", text: 'Maximum Depth'},
        {link: "../swmmReact/App_C_JUNCTIONSDinitNUD", text: 'Initial Depth'},
        {link: "../swmmReact/App_C_JUNCTIONSDsurchNUD", text: 'Surcharge Depth'},
        {link: "../swmmReact/App_C_JUNCTIONSApondedNUD", text: 'Ponded Area'},
      ]
    },
    {
      setName: 'Controls: Infiltration',
      linkData: [
        {link: "../swmmReact/App_C_INFILTRATIONConductivityNUD", text: 'Conductivity'},
        {link: "../swmmReact/App_C_INFILTRATIONCurveNoNUD", text: 'Curve No.'},
        {link: "../swmmReact/App_C_INFILTRATIONDecayNUD", text: 'Decay'},
        {link: "../swmmReact/App_C_INFILTRATIONIMDNUD", text: 'Initial Deficit'},
        {link: "../swmmReact/App_C_INFILTRATIONInfilMethodNUD", text: 'Infiltration Method'},
        {link: "../swmmReact/App_C_INFILTRATIONMaxInfilNUD", text: 'Maximum Infiltration'},
        {link: "../swmmReact/App_C_INFILTRATIONMaxRateNUD", text: 'Maximum Rate'},
        {link: "../swmmReact/App_C_INFILTRATIONMinRateNUD", text: 'Minimum Rate'},
        {link: "../swmmReact/App_C_INFILTRATIONPSINUD", text: 'Suction Head'},
        {link: "../swmmReact/App_C_INFILTRATIONDryingTime3NUD", text: 'Drying Time (primary)'},
        {link: "../swmmReact/App_C_INFILTRATIONDryingTime4NUD", text: 'Drying Time (secondary)'}
      ]
    },
    {
      setName: 'Controls: Aquifers',
      linkData: [
        {link: "../swmmReact/App_C_AQUIFERSEbotNUD", text: 'Bottom elevation'},
        {link: "../swmmReact/App_C_AQUIFERSEgwNUD", text: 'Water table elevation'},
        {link: "../swmmReact/App_C_AQUIFERSETsNUD", text: 'Lower evap. depth'},
        {link: "../swmmReact/App_C_AQUIFERSETuNUD", text: 'Upper evap. fraction'},
        {link: "../swmmReact/App_C_AQUIFERSFCNUD", text: 'Field capacity'},
        {link: "../swmmReact/App_C_AQUIFERSKslpNUD", text: 'Conductivity slope'},
        {link: "../swmmReact/App_C_AQUIFERSKsNUD", text: 'Conductivity'},
        {link: "../swmmReact/App_C_AQUIFERSPorNUD", text: 'Porosity'},
        {link: "../swmmReact/App_C_AQUIFERSSeepNUD", text: 'Lower GW loss rate'},
        {link: "../swmmReact/App_C_AQUIFERSTslpNUD", text: 'Tension slope'},
        {link: "../swmmReact/App_C_AQUIFERSUmcNUD", text: 'Unsat. zone moisture'},
        {link: "../swmmReact/App_C_AQUIFERSWPNUD", text: 'Wilting point'},
      ]
    },
    {
      setName: 'Controls: Conduits',
      linkData: [
        {link: "../swmmReact/App_C_CONDUITSInitFlowNUD", text: 'Initial flow'},
        {link: "../swmmReact/App_C_CONDUITSInOffsetNUD", text: 'Inlet offset'},
        {link: "../swmmReact/App_C_CONDUITSLengthNUD", text: 'Length'},
        {link: "../swmmReact/App_C_CONDUITSMaxFlowNUD", text: 'Max. flow'},
        {link: "../swmmReact/App_C_CONDUITSOutOffsetNUD", text: 'Outlet offset'},
        {link: "../swmmReact/App_C_CONDUITSRoughnessNUD", text: 'Roughness'},
      ]
    },
    {
      setName: 'Controls: Groundwater',
      linkData: [
        {link: "../swmmReact/App_C_GROUNDWATERA1NUD", text: 'A1 coefficient'},
        {link: "../swmmReact/App_C_GROUNDWATERA2NUD", text: 'A2 coefficient'},
        {link: "../swmmReact/App_C_GROUNDWATERA3NUD", text: 'A3 coefficient'},
        {link: "../swmmReact/App_C_GROUNDWATERB1NUD", text: 'B1 coefficient'},
        {link: "../swmmReact/App_C_GROUNDWATERB2NUD", text: 'B2 coefficient'},
        {link: "../swmmReact/App_C_GROUNDWATERDswNUD", text: 'Surface water depth'},
        {link: "../swmmReact/App_C_GROUNDWATEREbotNUD", text: 'Aquifer bottom elevation'},
        {link: "../swmmReact/App_C_GROUNDWATEREgwNUD", text: 'Initial water table elev'},
        {link: "../swmmReact/App_C_GROUNDWATEREsurfNUD", text: 'Surface elevation'},
        {link: "../swmmReact/App_C_GROUNDWATERUmcNUD", text: 'Unsat. zone moisture'}
      ]
    },
    {
      setName: 'Controls: Outfalls',
      linkData: [
        {link: "../swmmReact/App_C_OUTFALLSInvertNUD", text: 'Invert El.'},
      ]
    },
    {
      setName: 'Controls: Pumps',
      linkData: [
        {link: "../swmmReact/App_C_PUMPSShutoffNUD", text: 'Shutoff Depth'},
        {link: "../swmmReact/App_C_PUMPSStartupNUD", text: 'Startup Depth'},
      ]
    },
  ]

  // Array to hold refs and inView states
  const categoryRefs = []

  // Loop to create refs and inView states
  for (let i = 0; i < linkSet.length; i++) {
    const [ref, inView] = useInView({
      triggerOnce: false,
      rootMargin: "100px 0px 200px 0px",
    });

    // Push refs and inView states to the array
    categoryRefs.push({ ref, inView })
  }

  const [expandedArray, setExpandedArray] = useState(Array.from({length: linkSet.length}).map(()=>true))

  const handleExpandedMenu = (i) => {
    setExpandedArray(prevState => {
      const newState = [...prevState]
      newState[i] = !newState[i]
      return newState
    })
  }

  return (
    <>
      <ul>
        {
          linkSet.map((v, i)=>(
          <motion.div
            key={v+i}
            ref={categoryRefs[i].ref}
            initial={{ y: categoryRefs[i].inView ? 300 : 0 }}
            animate={{ y: categoryRefs[i].inView ? 0 : 300 }}
            transition={{duration: 0.5}}
          >
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1, transition: {duration: 1} }}
            exit={{ opacity: 0, transition: {duration: 0} }}
          >
            <div onClick={()=>handleExpandedMenu([i])} className='listbox'>
            <h3 >{v.setName}</h3>
            <motion.ul
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: expandedArray[i] ? 'auto' : 0, opacity: expandedArray[i] ? 1 : 0 }}
              transition={{ duration: 0.3 }}
              style={{ overflow: 'hidden' }}
            >
              {
                v.linkData.map((vv, ii)=>(
                  <motion.div
                    key={vv+ii}
                    initial={{ x: 300 }}
                    whileInView={{ x: 0, transition: {duration: 0.25} }}
                    exit={{ x: 300, transition: {duration: 0} }}
                  >
                    <li><ListElementAnimated link={vv.link} text={vv.text} /></li>
                  </motion.div>
                ))
              }
              </motion.ul>
            </div>
          </motion.div>
          </motion.div>
          ))
        }
      </ul>
    </>
  )
}

export default DropList